import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sumBy } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import Big from 'big.js'

import { useLazyListCartsQuery } from 'orders/reduxQueries'
import { Cart as CartSchema } from 'orders/schema'
import { LoadDataParams, ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { BaseRouteParams, useNavigation } from 'hocs'
import Money from 'uiComponents/money'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus'
import { pathUtils } from 'utils/pathnameFormatter'
import PaymentDeadlineCell from './paymentDeadlineCell'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { ORDER_PATHS } from 'orders/paths'
import { MarkCartAsPaidConfirmationDialog } from './markCartAsPaidConfirmationDialog'
import TooltipWrapper from 'uiComponents/tooltip/tooltipWrapper'
import './cartTable.scss'
import { dateRangeFromQuery } from 'dateRanges'
import { format } from 'date-fns'
import { InlineEdit } from 'uiComponents/input'
import { DateFormats, formatISOString } from 'utils'
import CartTableCell from './cartTableCell'
import CartTableFilters from './filters/cartTableFilter'
import { getFiltersFromUrl } from 'uiComponents/filter/filterHelpers'
import StatusLabel from 'orders/orders/commons/orderStatusLabel'
import { useHasFeature } from 'features'

const emptyData = [] as any[]
const defaultStatusFilter = 'open,pending,initiated'

const CartTable = () => {
    const [listCarts, { isFetching, data }] = useLazyListCartsQuery()
    const { accountSlug } = useParams<BaseRouteParams>()
    const navigation = useNavigation()
    const history = useHistory()
    const query = navigation.query()
    const [markAsPaidCartId, setMarkAsPaidCartId] = useState<string | null>(null)
    const areFiltersEnabled = useHasFeature('show_reservation_filters')

    useEffect(() => {
        const statusFilter = getFiltersFromUrl('reservation_status')
        if (statusFilter !== '') return

        const searchParams = new URLSearchParams(location.search)
        const filterValue = encodeURIComponent(`reservation_status:${defaultStatusFilter}`)
        searchParams.set('filter', filterValue)

        history.replace({
            pathname: location.pathname,
            search: searchParams.toString(),
        })
    }, [navigation])

    const dateRangeParams = useMemo(() => {
        const { dateRangeType } = query
        const { from, to } = dateRangeFromQuery(query, 'last30days')
        const dateFilterKey = dateRangeType || 'created_at'

        return {
            [`from_${dateFilterKey}`]: format(from, 'yyyy-MM-dd'),
            [`to_${dateFilterKey}`]: format(to, 'yyyy-MM-dd'),
        }
    }, [navigation])

    const statusFilter = useMemo(() => getFiltersFromUrl('reservation_status'), [navigation])

    const onLoadData = useCallback(
        ({ token, pagination: { pageSize = 10 } }: LoadDataParams) => {
            listCarts({
                accountSlug,
                query: pathUtils.formatSerchParams({
                    cursor: token,
                    page_size: pageSize,
                    search: query.q,
                    status: statusFilter,
                    ...dateRangeParams,
                }),
            })
        },
        [accountSlug, dateRangeParams, statusFilter],
    )

    const columns = useMemo(
        () =>
            [
                {
                    Header: () => null,
                    id: 'expander',
                    accessor: 'expander',
                    width: '3.5rem',
                    disableSortBy: true,
                    disableHideColumn: true,
                    Cell: ({ row }) => {
                        return (
                            <div {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? (
                                    <FontAwesomeIcon icon={faAngleUp as IconProp} className="opened-icon" />
                                ) : (
                                    <FontAwesomeIcon icon={faAngleDown as IconProp} className="closed-icon" />
                                )}
                            </div>
                        )
                    },
                },
                {
                    accessor: 'status',
                    Header: 'Status',
                    width: '6rem',
                    Cell: ({ value }) => <StatusLabel status={value} />,
                },
                {
                    accessor: 'referenceId',
                    Header: 'Reservation ID',
                    width: '10rem',
                    Cell: ({ value }) => <span title={value}>{value}</span> || '-',
                },
                {
                    accessor: 'customer.email',
                    Header: 'Email',
                    width: '15.5rem',
                    Cell: ({ value }) => (
                        <InlineEdit id="email" value={value} maskData={true} maskIcon={true} disabled>
                            {value}
                        </InlineEdit>
                    ),
                },
                {
                    accessor: 'quantity',
                    Header: 'Qty',
                    width: '4rem',
                    Cell: ({ row: { original } }) => {
                        return sumBy(
                            original.items.filter((item) => !!item.amount),
                            (cartItem) => cartItem.amount,
                        )
                    },
                },
                {
                    accessor: 'total',
                    Header: 'Total',
                    width: '6rem',
                    Cell: ({ row: { original } }) => {
                        const total = Big(original.totalPriceAfterDiscount).toNumber()
                        return <Money amount={total} accountSlug={accountSlug} />
                    },
                },
                {
                    accessor: 'expiresAt',
                    Header: 'Payment deadline',
                    width: '10.5rem',
                    Cell: ({
                        value,
                        row: {
                            original: { id },
                        },
                    }) => <PaymentDeadlineCell value={value} cartId={id} />,
                },
                {
                    accessor: 'eventDate',
                    Header: 'Visit date',
                    width: '8rem',
                    Cell: ({ value }) => (value ? formatISOString(value, `${DateFormats.LONG_DATE}`) : '-'),
                },
                {
                    accessor: 'startTime',
                    Header: 'Visit time',
                    width: '6.25rem',
                    Cell: ({ value }) => {
                        if (!value) return '-'
                        const [hours, minutes, _seconds] = value.split(':')
                        const formattedTime = `${hours}:${minutes}`
                        return <span title={formattedTime}>{formattedTime}</span>
                    },
                },
                {
                    accessor: 'createdAt',
                    Header: 'Created on',
                    width: '9rem',
                    Cell: ({ value, row: { original } }) =>
                        value
                            ? formatISOString(value, `${DateFormats.LONG_DATE}, ${DateFormats.SHORT_TIME}`, {
                                  timeZone: original.timezone,
                              })
                            : '-',
                },
                {
                    accessor: 'updatedAt',
                    Header: 'Last edit',
                    width: '9rem',
                    Cell: ({ value, row: { original } }) =>
                        value
                            ? formatISOString(value, `${DateFormats.LONG_DATE}, ${DateFormats.SHORT_TIME}`, {
                                  timeZone: original.timezone,
                              })
                            : '-',
                },
                {
                    accessor: 'id',
                    align: 'right',
                    style: {
                        overflow: 'visible',
                    },
                    width: '9.5rem',
                    classNames: '',
                    Cell: ({ value, cell }) => {
                        const urls = cell.row.original.urls || []

                        return (
                            <OnClickMenu title="Actions" kind="action" secondary usePortal>
                                <OnClickMenuItem>
                                    {!urls.find(({ rel }) => rel === 'resume')?.url ? (
                                        <TooltipWrapper text="Payment initiated" className="disabled-dropdown-item">
                                            Edit Cart
                                        </TooltipWrapper>
                                    ) : (
                                        <UnstyledLink
                                            to={pathUtils.populateParams(ORDER_PATHS.fullPaths.cartEditPage, {
                                                accountSlug,
                                                id: value,
                                            })}
                                        >
                                            Edit Cart
                                        </UnstyledLink>
                                    )}
                                </OnClickMenuItem>
                                <OnClickMenuItem onClick={() => setMarkAsPaidCartId(value)}>
                                    Mark as paid
                                </OnClickMenuItem>
                            </OnClickMenu>
                        )
                    },
                },
            ] as TableColumn<CartSchema>[],
        [accountSlug],
    )

    return (
        <div className="cart-table-container">
            {areFiltersEnabled && (
                <div className="cart-table-header">
                    <CartTableFilters />
                </div>
            )}
            <ReactTable
                sticky
                noResultsRow
                pagination={{
                    next: data?.next,
                    previous: data?.previous,
                }}
                expanded={CartTableCell}
                loading={isFetching}
                loadData={onLoadData}
                data={data?.results || emptyData}
                columns={columns}
                storeStateInQuery={false}
                elevation={true}
                tableProps={{ className: 'cart-table' }}
            />
            <MarkCartAsPaidConfirmationDialog
                accountSlug={accountSlug}
                cartId={markAsPaidCartId}
                onConfirm={() => setMarkAsPaidCartId(null)}
                onCancel={() => setMarkAsPaidCartId(null)}
            />
        </div>
    )
}

export default CartTable
