import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Timeline from 'react-visjs-timeline'
import CapacityItemTooltip from './CapacityItemTooltip'
import { LoaderWrapper } from 'uiComponents/loaders'
import { getItemTemplate, getGroupTemplate } from './TimelineItem'
import { useAppSelector } from 'store/hooks'
import { getCalendarMax, getCalendarMin } from '../utils'
import { getActiveAccount } from 'preferences/selectors'
import { TimelineItem } from '../types'
import { DateFormats, formatISOString } from 'utils'
import { offset, useFloating } from '@floating-ui/react'
import { TimelineOptions } from 'vis-timeline'
import { getCapacityReportData } from '../state/reduxQuery'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { getFiltersFromUrl } from 'uiComponents/filter/filterHelpers'
import { useGetAccountLocationsQuery } from 'orders/reduxQueries'

const headerHeight = 48
const lineHeight = 64

type CapacityReportTimelineProps = {
    selectedDate: Date
}

const CapacityReportTimeline: FC<CapacityReportTimelineProps> = ({ selectedDate }) => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const accountSlug = useAppSelector(getActiveAccount)
    if (!accountSlug) throw new Error('No account selected')
    const { isReady, isLoading, items, groups, openingTime } = useAppSelector((state) => state.capacityReport)
    const { data: locationData } = useGetAccountLocationsQuery({ accountSlug })
    const [isInitialDrawDone, setIsInitialDrawDone] = useState(false)
    const [isTooltipVisible, setIsTooltipVisible] = useState(true)
    const [tooltipTargetElement, setTooltipTargetElement] = useState<HTMLDivElement | null | undefined>()
    const [tooltipItem, setTooltipItem] = useState<TimelineItem | null | undefined>()
    const timelineRef = useRef<typeof Timeline>()
    const drawCheckIntervalRef = useRef<number | null>(null)
    const debouncedShowTooltipRef = useRef<ReturnType<typeof debounce> | null>(null)

    const timelineHeight = useMemo(() => groups && groups.length * lineHeight + headerHeight + 'px', [groups])

    const { x, y, strategy, refs } = useFloating({
        placement: 'top',
        open: isTooltipVisible,
        middleware: [offset(8)],
        elements: {
            reference: tooltipTargetElement,
        },
    })

    const options: TimelineOptions | null = useMemo(() => {
        if (!openingTime) return null

        const dateStart = getCalendarMin(formatISOString(selectedDate, 'yyyy-MM-dd'), openingTime.openingTime)
        const dateEnd = getCalendarMax(formatISOString(selectedDate, 'yyyy-MM-dd'), openingTime.closingTime)

        return {
            width: '100%',
            groupHeightMode: 'fixed',
            height: timelineHeight,
            maxHeight: '636px',
            template: getItemTemplate,
            groupTemplate: getGroupTemplate,
            showMinorLabels: true,
            showMajorLabels: false,
            showTooltips: true,
            timeAxis: {
                scale: 'hour',
            },
            zoomable: true,
            zoomKey: 'metaKey',
            horizontalScroll: true,
            verticalScroll: true,
            start: dateStart,
            min: dateStart,
            end: dateEnd,
            max: dateEnd,
            orientation: 'top',
            autoResize: true,
            locale: 'en_US',
            stack: false,
            xss: {
                disabled: true,
            },
            format: {
                minorLabels: (dateObj) => {
                    // @ts-expect-error incorrect lib date types
                    const { _d: date } = dateObj
                    return formatISOString(date, DateFormats.SHORT_TIME)
                },
            },
        }
    }, [timelineHeight, openingTime, selectedDate])

    useEffect(() => {
        const [locationId] = getFiltersFromUrl('location_id').split(',')

        const shouldRefetch =
            locationId !== openingTime?.id ||
            format(selectedDate, 'yyyy-MM-dd') !== new URLSearchParams(location.search).get('date')

        if (accountSlug && shouldRefetch && !isLoading) {
            dispatch(
                getCapacityReportData({
                    accountSlug,
                    date: format(selectedDate, 'yyyy-MM-dd'),
                    ...(locationId && { locationId }),
                }),
            )
            setIsInitialDrawDone(false)
        }
    }, [accountSlug, location, selectedDate, dispatch])

    useEffect(() => {
        if (isReady && !isInitialDrawDone) {
            const checkInitialDraw = () => {
                if (timelineRef.current?.$el.initialDrawDone) {
                    setIsInitialDrawDone(true)
                } else {
                    drawCheckIntervalRef.current = window.setTimeout(checkInitialDraw, 100)
                }
            }

            drawCheckIntervalRef.current = window.setTimeout(checkInitialDraw, 100)
        }

        return () => {
            if (drawCheckIntervalRef.current !== null) {
                clearTimeout(drawCheckIntervalRef.current)
            }
        }
    }, [isReady, isInitialDrawDone])

    useEffect(() => {
        if (!selectedDate) return

        const searchParams = new URLSearchParams(location.search)
        searchParams.set('date', format(selectedDate, 'yyyy-MM-dd'))

        history.replace({
            pathname: location.pathname,
            search: searchParams.toString(),
        })
    }, [selectedDate])

    useEffect(() => {
        const [selectedLocationId] = getFiltersFromUrl('location_id').split(',')

        if (!selectedLocationId && openingTime && locationData?.length && locationData?.length > 1) {
            const searchParams = new URLSearchParams(location.search)
            const filterValue = encodeURIComponent(`location_id:${openingTime?.id}`)
            searchParams.set('filter', filterValue)

            history.replace({
                pathname: location.pathname,
                search: searchParams.toString(),
            })
        }
    }, [openingTime, locationData])

    const handleChanged = useCallback(() => {
        if (!groups) return
        const itemset: HTMLDivElement = document.querySelector('.vis-center .vis-content')!
        itemset.style.height = groups.length * lineHeight + 'px'
    }, [groups])

    const showTooltip = useCallback(
        (element: HTMLDivElement, itemId: string) => {
            setTooltipTargetElement(element)
            setTooltipItem(items?.find((item) => item.id === itemId))
            setIsTooltipVisible(true)
        },
        [items],
    )

    const debouncedShowTooltip = useMemo(() => {
        const debouncedFn = debounce(showTooltip, 500)
        debouncedShowTooltipRef.current = debouncedFn
        return debouncedFn
    }, [showTooltip])

    const handleMouseOver = useCallback(
        (props) => {
            const { event, item: itemId } = props
            event.stopPropagation()
            const element = event.target.closest('.vis-item')

            if (element && items) {
                debouncedShowTooltip(element, itemId)
            }
        },
        [items, setIsTooltipVisible, setTooltipTargetElement, setTooltipItem],
    )

    const handleMouseOut = useCallback(
        (props) => {
            const { event } = props
            event.stopPropagation()

            if (debouncedShowTooltipRef.current) {
                debouncedShowTooltipRef.current.cancel()
            }

            setTooltipTargetElement(null)
            setTooltipItem(null)
            setIsTooltipVisible(false)
        },
        [setIsTooltipVisible, setTooltipItem, setTooltipTargetElement],
    )

    if (!isReady) return <LoaderWrapper loading />

    return (
        <div className="capacity-timeline">
            <LoaderWrapper loading={!isInitialDrawDone} />
            <Timeline
                options={options}
                items={items}
                groups={groups}
                ref={timelineRef}
                itemoverHandler={handleMouseOver}
                itemoutHandler={handleMouseOut}
                changedHandler={handleChanged}
            />
            <div className="capacity-timeline__footer">
                <div className="capacity-timeline__footer__availability">
                    <h6>Availability:</h6>
                    <div className="availability-scale" />
                </div>
            </div>
            {isTooltipVisible && tooltipItem && (
                <CapacityItemTooltip item={tooltipItem} ref={refs.setFloating} x={x} y={y} strategy={strategy} />
            )}
        </div>
    )
}

export default CapacityReportTimeline
