import React, { useState } from 'react'
import styled, { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { Link } from 'react-router-dom'
import { Currency, withCurrency } from 'uiComponents/money/moneyHoc'
import { Col, Row } from 'uiComponents/flex'
import { ActionButton } from 'uiComponents/buttons'
import {
    BigCheckbox,
    Checkbox,
    CheckboxColumn,
    IconTextInput,
    InlineEdit,
    MultiSelect,
    NumberInput,
    PersistentToggle,
    Radio,
    SingleSelect,
    Slider,
    SliderWithInput,
    TextArea,
    TextInput,
    TimeInput,
    Toggle,
    ToggleWithValues,
} from 'uiComponents/input'
import { WrapWithSymbol } from 'uiComponents/form/formElements'
import { faCheck, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import Popover from 'uiComponents/popups/popover'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { Card, HelpCard, InfoCard } from 'uiComponents/cards'
import { Tooltip } from 'uiComponents/tooltip'
import Infotip, { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { UploadLoader } from 'uiComponents/loaders'
import ColorPicker from 'uiComponents/colorPicker'
import { Tag } from 'uiComponents/tag'
import { ToggleBox } from 'uiComponents/toggleBox'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { HorizontalLine, TextWithHorizontalLine } from 'uiComponents/pageElements'
import { Cell, DataRow, DataTable, HeaderRow, InlineDataTable, TableHeader } from 'uiComponents/table'
import PieChart, { ChartData } from 'uiComponents/charts/pieChart'
import BarChart, { BarChartSeries } from 'uiComponents/charts/barChart'
import TimeseriesBarChart, { Series } from 'uiComponents/charts/timeseriesBarChart'
import { ChartWrapperWithTotals } from 'reports/helpers'
import {
    OnClickMenu,
    OnClickMenuItem,
    OneLevelMenu,
    OptionBox,
    OptionCheckBox,
    OptionsList,
    PopupMenu,
    TwoLevelMenu,
} from 'uiComponents/menus/'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { StatusMessageContainer } from 'uiComponents/messages'
import { ModalDialog } from 'uiComponents/popups/modal'
import lockIcon from 'admin/lock.svg'
import { ScrollTableContainer } from 'uiComponents/table/scrollTableContainer'
import { PaginationSection } from 'uiComponents/table/pagination'
import { ActionsSection } from 'uiComponents/filter/filterComponents'
import { NavigationTabs, Tabs } from 'uiComponents/navigation/tabs'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import cloneDeep from 'lodash/cloneDeep'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import BreadCrumbs from 'uiComponents/navigation/breadCrumbs'
import SearchBar from 'uiComponents/search/searchBar'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { Text } from 'uiComponents/typography'
import SearchWithButtonField from 'uiComponents/search/searchWithButton'
import SearchField from 'uiComponents/search/searchField'
import { Typography } from './typography'
import { Alert, AlertTitle, Button, ToggleButton, ToggleButtonGroup } from '@mui/material'
import PeopleRoofSolid from '@convious/icons/src/icons/solid/peopleRoofSolid.svg'
import PeopleDoorSolid from '@convious/icons/src/icons/solid/peopleDoorSolid.svg'
import VariationLowIcon from '@convious/icons/src/icons/solid/variationLow.svg'
import VariationMediumIcon from '@convious/icons/src/icons/solid/variationMed.svg'
import VariationHighIcon from '@convious/icons/src/icons/solid/variationHigh.svg'
import { PencilSolid } from '@convious/icons/src'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './dashboardComponentsPage.scss'

interface DashboardComponentsProps {
    theme: DashboardTheme
    getCurrency: (accountSlug: string) => Currency
}

const StrongLabel = styled.div`
    margin: 1em 0;
    font-weight: bold;
    font-size: 1.5em;
    display: block;
    text-decoration: none;
    color: ${(props) => props.theme.colors.textDark};
`
const MenuLink = styled.a`
    display: block;
`
const RegularLabel = styled.div`
    margin: 0.5em;
    font-size: 1em;
`

const Container = styled.div`
    margin: 5em 0 5em 7em;
    padding: 2em 4em;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    background: #fff;
    width: 90%;
    height: fit-content;
`
const ComponentBlock = styled.div`
    margin: 1em 0 5em 0.5em;
`

const SubBlock = styled.div`
    margin: 1.5em 0 1.5em 0;
`
const ColourCube = styled.span`
    padding: 0.7em;
    width: 25em;
    margin: 0.5em;
    display: block;
`
const SmallColourCube = styled.span`
    margin: 0.5em;
    padding: 0 0.8em;
    line-height: 1.5;
`
const ButtonTypeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 35em;
    margin-bottom: 0.5em;
    span {
        width: 12rem;
    }
`
const columnWidths = ['6.25em', '10.5em', null, '8.75em']

const scrollableTableColumnWidths = ['10em', '15em', '15em', '15em', '15em', '15em', '15em', '15em']

const exampleChartData: ChartData[] = [
    { value: 1548, name: 'article1' },
    { value: 535, name: 'article2' },
    { value: 510, name: 'article3' },
    { value: 634, name: 'article4' },
    { value: 735, name: 'article5' },
]

const exampleChartSeries: Series[] = [
    {
        name: 'Example data',
        type: 'bar',
        data: ['10', '12', '15', '19', '-20', '-70', '1'],
    },
]

const exampleBarChartSeries: BarChartSeries[] = [
    {
        name: 'Example data 1',
        type: 'bar',
        barGap: 0,
        data: [
            { name: 'Monday', value: 10 },
            { name: 'Tuesday', value: 30 },
            { name: 'Wednesday', value: 45 },
            { name: 'Thursday', value: 16 },
            { name: 'Friday', value: 36 },
            { name: 'Saturday', value: 6 },
            { name: 'Sunday', value: 50 },
        ],
    },
    {
        name: 'Example data 2',
        type: 'bar',
        barGap: 0,
        data: [
            { name: 'Monday', value: 56 },
            { name: 'Tuesday', value: 20 },
            { name: 'Wednesday', value: 60 },
            { name: 'Thursday', value: 42 },
            { name: 'Friday', value: 10 },
            { name: 'Saturday', value: 50 },
            { name: 'Sunday', value: 22 },
        ],
    },
]

const exampleAxisData: string[] = ['mon', 'tuesd', 'wed', 'thu', 'fri', 'sat', 'sun']
const selectOptions = [
    { value: 'option1', name: 'Select option 1' },
    { value: 'option2', name: 'Select option 2' },
    { value: 'option3', name: 'Select option 3' },
    { value: 'option4', name: 'Select option 4' },
]
const menuOptions = [
    { value: 'all', text: 'Click to see menu options' },
    { value: 'completed', text: 'Some subsection' },
    { value: 'running', text: 'Another option' },
]
const twoLevelMenuOptions = [
    { slug: 'first', name: 'Click to see second level' },
    { slug: 'second', name: 'Click me too' },
    { slug: 'third', name: 'Yet another clickable option' },
]
const oneLevelMenuOptions = [
    { slug: 'first', name: 'First option' },
    { slug: 'second', name: 'Second option' },
    { slug: 'third', name: 'Third option' },
]

function DashboardComponents(props: DashboardComponentsProps) {
    const [toggleOn, setToggleValue] = useState<boolean>(false)
    const [toggleRight, setToggleSideValue] = useState<boolean>(true)
    const [radioChecked, setRadioChecked] = useState<'a' | 'b'>('a')
    const [activeToggleValue, setActiveToggleValue] = useState<'currency' | 'percent'>('percent')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [color, setColor] = useState<string>('#5298F4')
    const [closeSingleLevelFilter, setCloseSingleLevelFilter] = useState<boolean>(false)
    const [selectedSingleLevelFilters, setSelectedSingleLevelFilters] = useState<string[]>([])
    const [sliderValue, setSliderValue] = useState<string>('0')
    const [verticalSliderValue, setVerticalSliderValue] = useState<string>('0')
    const [checkboxColumnValues, setCheckboxColumnValues] = useState<boolean[]>([true, true, false, true])
    const [toggleGroupValue, setToggleGroupValue] = useState<'small' | 'medium' | 'large' | null>(null)

    React.useEffect(() => {
        if (closeSingleLevelFilter) {
            setCloseSingleLevelFilter(false)
        }
    }, [closeSingleLevelFilter])

    const toggleSingleFilterOptions = (slug: string) => {
        let updatedSelection = cloneDeep(selectedSingleLevelFilters)
        updatedSelection.indexOf(slug) > -1
            ? (updatedSelection = updatedSelection.filter((f) => f !== slug))
            : updatedSelection.push(slug)
        setSelectedSingleLevelFilters(updatedSelection)
    }

    return (
        <Container id="styleguide">
            <ComponentBlock>
                <StrongLabel id="menu">Menu:</StrongLabel>
                <MenuLink href="#typography">Typography</MenuLink>
                <MenuLink href="#colors">Colors</MenuLink>
                <MenuLink href="#buttons">Buttons</MenuLink>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="typography">Typography:</StrongLabel>
                <Typography />
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="colors">Colors:</StrongLabel>
                <ColourCube style={{ backgroundColor: '#FF155B' }}>convious #FF155B</ColourCube>
                <ColourCube style={{ backgroundColor: '#5298F4' }}>boyBlue #5298F4</ColourCube>
                <ColourCube style={{ backgroundColor: '#5298F4' }}>boyBlue #5298F4</ColourCube>
                <ColourCube style={{ backgroundColor: '#19CA80' }}>emerald #19CA80</ColourCube>
                <ColourCube style={{ backgroundColor: '#DE4545' }}>sunrise #DE4545</ColourCube>
                <ColourCube style={{ backgroundColor: '#FF9145' }}>tiger #FF9145</ColourCube>
                <ColourCube style={{ backgroundColor: '#FFC800' }}>sun #FFC800</ColourCube>
                <ColourCube style={{ backgroundColor: '#FFFFFF' }}>white #FFFFFF</ColourCube>
                <ColourCube style={{ backgroundColor: '#0D151E', color: '#FFFFFF' }}>richBlack #0D151E</ColourCube>
                <ColourCube style={{ backgroundColor: '#3D4854', color: '#FFFFFF' }}>textDark #3D4854</ColourCube>
                <ColourCube style={{ backgroundColor: '#6A7E92' }}>aluminium #6A7E92</ColourCube>
                <ColourCube style={{ backgroundColor: '#97A5B4' }}>textLight #97A5B4</ColourCube>
                <ColourCube style={{ backgroundColor: '#D1D8DF' }}>border #D1D8DF</ColourCube>
                <ColourCube style={{ backgroundColor: '#F5F7FA' }}>background #F5F7FA</ColourCube>
                <ColourCube style={{ backgroundColor: '#FAFAFB' }}>tableRow #FAFAFB</ColourCube>
                <div style={{ margin: '2em .5em .5em .5em' }}>Aluminium shades:</div>
                {Object.keys(props.theme.colors.aluminiumShades).map((c) => (
                    <SmallColourCube key={c} style={{ backgroundColor: props.theme.colors.aluminiumShades[c] }}>
                        {c}: {props.theme.colors.aluminiumShades[c]}
                    </SmallColourCube>
                ))}
                <div style={{ margin: '2em .5em .5em .5em' }}>Boy Blue shades:</div>
                {Object.keys(props.theme.colors.boyBlueShades).map((c) => (
                    <SmallColourCube key={c} style={{ backgroundColor: props.theme.colors.boyBlueShades[c] }}>
                        {c}: {props.theme.colors.boyBlueShades[c]}
                    </SmallColourCube>
                ))}
                <div style={{ margin: '2em .5em .5em .5em' }}>Emerald shades:</div>
                {Object.keys(props.theme.colors.emeraldShades).map((c) => (
                    <SmallColourCube key={c} style={{ backgroundColor: props.theme.colors.emeraldShades[c] }}>
                        {c}: {props.theme.colors.emeraldShades[c]}
                    </SmallColourCube>
                ))}
                <div style={{ margin: '2em .5em .5em .5em' }}>boyBlue shades:</div>
                {Object.keys(props.theme.colors.boyBlueShades).map((c) => (
                    <SmallColourCube key={c} style={{ backgroundColor: props.theme.colors.boyBlueShades[c] }}>
                        {c}: {props.theme.colors.boyBlueShades[c]}
                    </SmallColourCube>
                ))}
                <div style={{ margin: '2em .5em .5em .5em' }}>chartRotatingColors:</div>
                {props.theme.colors.chartRotatingColors.map((c) => (
                    <SmallColourCube key={c} style={{ backgroundColor: c }}>
                        {c}
                    </SmallColourCube>
                ))}
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel>Typography:</StrongLabel>
                <Link to="/styleguide/typography">Link to new typography guide</Link>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="buttons">Buttons (Material UI):</StrongLabel>
                <br />
                <ButtonTypeContainer>
                    <span>Variant: Text (default):</span>
                    <Button size="small">Small</Button>
                    <Button size="medium">Medium</Button>
                    <Button size="large">Large</Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Text: Success:</span>
                    <Button color="success" size="small">
                        Small
                    </Button>
                    <Button color="success" size="medium">
                        Medium
                    </Button>
                    <Button color="success" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Text: Error:</span>
                    <Button color="error" size="small">
                        Small
                    </Button>
                    <Button color="error" size="medium">
                        Medium
                    </Button>
                    <Button color="error" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Text: Disabled:</span>
                    <Button disabled size="small">
                        Small
                    </Button>
                    <Button disabled size="medium">
                        Medium
                    </Button>
                    <Button disabled size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Contained</span>
                    <Button variant="contained" size="small">
                        Small
                    </Button>
                    <Button variant="contained" size="medium">
                        Medium
                    </Button>
                    <Button variant="contained" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Contained: Success:</span>
                    <Button variant="contained" color="success" size="small">
                        Small
                    </Button>
                    <Button variant="contained" color="success" size="medium">
                        Medium
                    </Button>
                    <Button variant="contained" color="success" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Contained: Error:</span>
                    <Button variant="contained" color="error" size="small">
                        Small
                    </Button>
                    <Button variant="contained" color="error" size="medium">
                        Medium
                    </Button>
                    <Button variant="contained" color="error" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Contained: Disabled:</span>
                    <Button variant="contained" disabled size="small">
                        Small
                    </Button>
                    <Button variant="contained" disabled size="medium">
                        Medium
                    </Button>
                    <Button variant="contained" disabled size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Outlined</span>
                    <Button variant="outlined" size="small">
                        Small
                    </Button>
                    <Button variant="outlined" size="medium">
                        Medium
                    </Button>
                    <Button variant="outlined" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Outlined: Success:</span>
                    <Button variant="outlined" color="success" size="small">
                        Small
                    </Button>
                    <Button variant="outlined" color="success" size="medium">
                        Medium
                    </Button>
                    <Button variant="outlined" color="success" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Outlined: Error:</span>
                    <Button variant="outlined" color="error" size="small">
                        Small
                    </Button>
                    <Button variant="outlined" color="error" size="medium">
                        Medium
                    </Button>
                    <Button variant="outlined" color="error" size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Outlined: Disabled:</span>
                    <Button variant="outlined" disabled size="small">
                        Small
                    </Button>
                    <Button variant="outlined" disabled size="medium">
                        Medium
                    </Button>
                    <Button variant="outlined" disabled size="large">
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Outlined: With Convious icon in left</span>
                    <Button variant="outlined" size="small" startIcon={<PencilSolid />}>
                        Small
                    </Button>
                    <Button variant="outlined" size="medium" startIcon={<PencilSolid />}>
                        Medium
                    </Button>
                    <Button variant="outlined" size="large" startIcon={<PencilSolid />}>
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Outlined: With Convious icon in right</span>
                    <Button variant="outlined" size="small" endIcon={<PencilSolid />}>
                        Small
                    </Button>
                    <Button variant="outlined" size="medium" endIcon={<PencilSolid />}>
                        Medium
                    </Button>
                    <Button variant="outlined" size="large" endIcon={<PencilSolid />}>
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Variant: Outlined: With FontAwesome icon in left</span>
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<FontAwesomeIcon icon={faPencilAlt} width={16} height={16} />}
                    >
                        Small
                    </Button>
                    <Button
                        variant="outlined"
                        size="medium"
                        startIcon={<FontAwesomeIcon icon={faPencilAlt} width={16} height={16} />}
                    >
                        Medium
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        startIcon={<FontAwesomeIcon icon={faPencilAlt} width={16} height={16} />}
                    >
                        Large
                    </Button>
                </ButtonTypeContainer>
                <ButtonTypeContainer style={{ justifyContent: 'flex-start' }}>
                    <span>ActionIcon:</span>
                    <ActionIcon icon={faPencilAlt} size="small" title="Edit" style={{ marginLeft: '3.5em' }} />
                    <ActionIcon icon={faPencilAlt} size="medium" title="Edit" style={{ marginLeft: '5em' }} />
                    <ActionIcon icon={faPencilAlt} size="large" title="Edit" style={{ marginLeft: '7em' }} />
                </ButtonTypeContainer>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="buttons">Toggle Buttons (Material UI):</StrongLabel>
                <br />
                <ButtonTypeContainer>
                    <span>Grouped: size: small, Color: standard:</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        exclusive={true}
                        size="small"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">One</ToggleButton>
                        <ToggleButton value="medium">Two</ToggleButton>
                        <ToggleButton value="large">Three</ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: size: small, Color: standard, disabled</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        exclusive={true}
                        size="small"
                        disabled
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">One</ToggleButton>
                        <ToggleButton value="medium">Two</ToggleButton>
                        <ToggleButton value="large">Three</ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: size: medium, Color: primary:</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        color="primary"
                        exclusive={true}
                        size="medium"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">One</ToggleButton>
                        <ToggleButton value="medium">Two</ToggleButton>
                        <ToggleButton value="large">Three</ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: size: large, Color: secondary:</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        color="secondary"
                        exclusive={true}
                        size="large"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">One</ToggleButton>
                        <ToggleButton value="medium">Two</ToggleButton>
                        <ToggleButton value="large">Three</ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: with icon, size: small, Color: standard:</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        exclusive={true}
                        size="small"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">
                            <PencilSolid />
                            One
                        </ToggleButton>
                        <ToggleButton value="medium">
                            <PencilSolid />
                            Two
                        </ToggleButton>
                        <ToggleButton value="large">
                            <PencilSolid />
                            Three
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: with icon, size: medium, Color: primary:</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        color="primary"
                        exclusive={true}
                        size="medium"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">
                            <PencilSolid />
                            One
                        </ToggleButton>
                        <ToggleButton value="medium">
                            <PencilSolid />
                            Two
                        </ToggleButton>
                        <ToggleButton value="large">
                            <PencilSolid />
                            Three
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: with icon, size: large, Color: secondary:</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        color="secondary"
                        exclusive={true}
                        size="large"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">
                            <PencilSolid />
                            One
                        </ToggleButton>
                        <ToggleButton value="medium">
                            <PencilSolid />
                            Two
                        </ToggleButton>
                        <ToggleButton value="large">
                            <PencilSolid />
                            Three
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: Icon toggles: medium: (used in pricing)</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        className="iconToggles"
                        exclusive={true}
                        size="medium"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small" aria-label="capacity-limit" size="medium">
                            <PeopleRoofSolid className="crowd-control-limit-type-icon" />
                            Capacity Limit
                        </ToggleButton>
                        <ToggleButton value="medium" aria-label="admission-limit" size="medium">
                            <PeopleDoorSolid className="crowd-control-limit-type-icon" />
                            Admission Limit
                        </ToggleButton>
                        <ToggleButton value="large" aria-label="capacity-limit" size="medium">
                            <PeopleRoofSolid className="crowd-control-limit-type-icon" />
                            Capacity Limit
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Grouped: Icon toggles: small: (used in pricing)</span>
                    <ToggleButtonGroup
                        value={toggleGroupValue}
                        className="iconToggles"
                        exclusive={true}
                        size="small"
                        onChange={(event: React.MouseEvent, value) => setToggleGroupValue(value)}
                    >
                        <ToggleButton value="small">
                            <VariationLowIcon width="20px" height="20px" />
                            Low ±5%
                        </ToggleButton>
                        <ToggleButton value="medium">
                            <VariationMediumIcon width="20px" height="20px" />
                            Medium ±12%
                        </ToggleButton>
                        <ToggleButton value="large">
                            <VariationHighIcon width="20px" height="20px" />
                            High ±20%
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Ungrouped: Color: primary:</span>
                    <ToggleButton
                        size="small"
                        value="small"
                        color="primary"
                        selected={toggleGroupValue === 'small'}
                        onChange={() => setToggleGroupValue('small')}
                    >
                        Small
                    </ToggleButton>
                    <ToggleButton
                        size="medium"
                        value="medium"
                        color="primary"
                        selected={toggleGroupValue === 'medium'}
                        onChange={() => setToggleGroupValue('medium')}
                    >
                        Medium
                    </ToggleButton>
                    <ToggleButton
                        size="large"
                        value="large"
                        color="primary"
                        selected={toggleGroupValue === 'large'}
                        onChange={() => setToggleGroupValue('large')}
                    >
                        Large
                    </ToggleButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Ungrouped: Variant: Contained, with icon</span>
                    <ToggleButton
                        size="small"
                        value="small"
                        selected={toggleGroupValue === 'small'}
                        onChange={() => setToggleGroupValue('small')}
                    >
                        <PencilSolid />
                        Small
                    </ToggleButton>
                    <ToggleButton
                        size="medium"
                        value="medium"
                        selected={toggleGroupValue === 'medium'}
                        onChange={() => setToggleGroupValue('medium')}
                    >
                        <PencilSolid />
                        Medium
                    </ToggleButton>
                    <ToggleButton
                        size="large"
                        value="large"
                        selected={toggleGroupValue === 'large'}
                        onChange={() => setToggleGroupValue('large')}
                    >
                        <PencilSolid />
                        Large
                    </ToggleButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Ungrouped: Variant: Contained, with icon, no text</span>
                    <ToggleButton
                        size="small"
                        value="small"
                        selected={toggleGroupValue === 'small'}
                        onChange={() => setToggleGroupValue('small')}
                    >
                        <PencilSolid />
                    </ToggleButton>
                    <ToggleButton
                        size="medium"
                        value="medium"
                        selected={toggleGroupValue === 'medium'}
                        onChange={() => setToggleGroupValue('medium')}
                    >
                        <PencilSolid />
                    </ToggleButton>
                    <ToggleButton
                        size="large"
                        value="large"
                        selected={toggleGroupValue === 'large'}
                        onChange={() => setToggleGroupValue('large')}
                    >
                        <PencilSolid />
                    </ToggleButton>
                </ButtonTypeContainer>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="buttons">Buttons (deprecated):</StrongLabel>
                <br />
                <ButtonTypeContainer>
                    <span>Default:</span>
                    <ActionButton size="small">Small</ActionButton>
                    <ActionButton size="medium">Medium</ActionButton>
                    <ActionButton size="large">Large</ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Action:</span>
                    <ActionButton kind="action" size="small">
                        Small
                    </ActionButton>
                    <ActionButton kind="action" size="medium">
                        Medium
                    </ActionButton>
                    <ActionButton kind="action" size="large">
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Success:</span>
                    <ActionButton kind="success" size="small">
                        Small
                    </ActionButton>
                    <ActionButton kind="success" size="medium">
                        Medium
                    </ActionButton>
                    <ActionButton kind="success" size="large">
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Destructive:</span>
                    <ActionButton kind="destructive" size="small">
                        Small
                    </ActionButton>
                    <ActionButton kind="destructive" size="medium">
                        Medium
                    </ActionButton>
                    <ActionButton kind="destructive" size="large">
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Disabled:</span>
                    <ActionButton disabled size="small">
                        Small
                    </ActionButton>
                    <ActionButton disabled size="medium">
                        Medium
                    </ActionButton>
                    <ActionButton disabled size="large">
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Default secondary:</span>
                    <ActionButton size="small" secondary>
                        Small
                    </ActionButton>
                    <ActionButton size="medium" secondary>
                        Medium
                    </ActionButton>
                    <ActionButton size="large" secondary>
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Action secondary:</span>
                    <ActionButton kind="action" size="small" secondary>
                        Small
                    </ActionButton>
                    <ActionButton kind="action" size="medium" secondary>
                        Medium
                    </ActionButton>
                    <ActionButton kind="action" size="large" secondary>
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Destructive secondary:</span>
                    <ActionButton kind="destructive" size="small" secondary>
                        Small
                    </ActionButton>
                    <ActionButton kind="destructive" size="medium" secondary>
                        Medium
                    </ActionButton>
                    <ActionButton kind="destructive" size="large" secondary>
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer>
                    <span>Disabled secondary:</span>
                    <ActionButton disabled size="small" secondary>
                        Small
                    </ActionButton>
                    <ActionButton disabled size="medium" secondary>
                        Medium
                    </ActionButton>
                    <ActionButton disabled size="large" secondary>
                        Large
                    </ActionButton>
                </ButtonTypeContainer>
                <ButtonTypeContainer style={{ justifyContent: 'flex-start' }}>
                    <span>ActionIcon:</span>
                    <ActionIcon icon={faPencilAlt} size="small" title="Edit" style={{ marginLeft: '3.5em' }} />
                    <ActionIcon icon={faPencilAlt} size="medium" title="Edit" style={{ marginLeft: '5em' }} />
                    <ActionIcon icon={faPencilAlt} size="large" title="Edit" style={{ marginLeft: '7em' }} />
                </ButtonTypeContainer>
            </ComponentBlock>
            <div style={{ fontSize: '1.5em', margin: '2em 0' }}>
                <Text status="error">Not yet updated components:</Text>
            </div>
            <HelpCard
                header="HelpCard Header"
                text="I will probably open some popup onclick (designed for non white background)"
                onClick={() => {}}
            />
            <ComponentBlock>
                <StrongLabel id="popup-menu">Menus:</StrongLabel>
                <div style={{ marginBottom: '2rem' }}>
                    <div>Popup Menu:</div>
                    <div style={{ marginBottom: '1rem' }}>
                        <Text status="error">Used in ab tests filter and landingpage stats card only!</Text>
                    </div>
                    <PopupMenu options={menuOptions} value="all" onChange={() => {}} />
                </div>
                <div style={{ marginBottom: '1em' }}>Multiple level / filter Menus:</div>
                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                    <div style={{ width: '13em' }}>
                        <TwoLevelMenu
                            menuToggleText="Two level menu"
                            firstLevelOptions={twoLevelMenuOptions}
                            disabledInfotipText=""
                            onActiveOptionChange={() => {}}
                            activeOptionOverride=""
                        >
                            <div style={{ padding: '1em' }}>
                                This can be any content
                                <br />
                                most probably another clickable list
                            </div>
                        </TwoLevelMenu>
                    </div>
                    <div style={{ fontSize: '.85em' }}>
                        <OneLevelMenu
                            menuName="One level menu"
                            closeMenu={closeSingleLevelFilter}
                            disabledInfotipText=""
                        >
                            <OptionsList>
                                {oneLevelMenuOptions.map((f) => (
                                    <OptionBox key={f.slug} onClick={() => toggleSingleFilterOptions(f.slug)}>
                                        <OptionCheckBox
                                            name={f.slug}
                                            checked={selectedSingleLevelFilters.indexOf(f.slug) > -1}
                                            onChange={() => {}}
                                        />
                                        <span>{f.name}</span>
                                    </OptionBox>
                                ))}
                            </OptionsList>
                            <ActionsSection>
                                <ActionButton secondary size="large" onClick={() => setCloseSingleLevelFilter(true)}>
                                    Cancel
                                </ActionButton>
                                <ActionButton
                                    onClick={() => setCloseSingleLevelFilter(true)}
                                    size="large"
                                    style={{ marginLeft: '1.5em' }}
                                >
                                    Apply
                                </ActionButton>
                            </ActionsSection>
                        </OneLevelMenu>
                    </div>
                </div>
                <div style={{ marginBottom: '1em' }}>OnClick Menus:</div>
                <div style={{ marginBottom: '1em' }}>
                    <OnClickMenu title="Default menu" kind="default" width="12em">
                        <OnClickMenuItem>Some link</OnClickMenuItem>
                        <OnClickMenuItem>e.g. a long download link</OnClickMenuItem>
                    </OnClickMenu>
                </div>
                <OnClickMenu title="Action secondary menu" kind="action" secondary alignRight width="13.5em">
                    <OnClickMenuItem>Action</OnClickMenuItem>
                    <OnClickMenuItem>Another action</OnClickMenuItem>
                </OnClickMenu>
            </ComponentBlock>
            <ComponentBlock style={{ marginBottom: '10em' }}>
                <StrongLabel id="loader">Loader:</StrongLabel>
                <div style={{ width: '20em' }}>
                    <UploadLoader />
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="modal">Modals:</StrongLabel>
                <ActionButton style={{ margin: '.5em' }} onClick={() => setShowModal(true)}>
                    Show modal
                </ActionButton>
                {showModal && (
                    <ModalDialog
                        onDismiss={() => {
                            setShowModal(false)
                        }}
                        interactive
                    >
                        <div>Modal window content</div>
                    </ModalDialog>
                )}
                <ActionButton style={{ margin: '.5em' }} onClick={() => setShowConfirmation(true)}>
                    Show confirmation
                </ActionButton>
                {showConfirmation && (
                    <ConfirmationDialog
                        title="Stop the campaign?"
                        text="By stopping the campaign your ads won’t be displayed anymore."
                        buttonText="Stop"
                        destructive
                        onCancel={() => setShowConfirmation(false)}
                        onConfirm={() => setShowConfirmation(false)}
                    />
                )}
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="modal">Search bars:</StrongLabel>
                <div style={{ marginBottom: '1em' }}>
                    <SearchWithButtonField placeholder="Search by product name or ID" />
                </div>
                <div style={{ marginBottom: '2em' }}>
                    <SearchField placeholder="Search..." />
                </div>
                <SearchBar
                    searchByOptions={[
                        { name: 'Order ID', value: 'id' },
                        { name: 'Name', value: 'name' },
                        { name: 'Visit date', value: 'date', inputType: 'date' },
                    ]}
                    minSearchLength={5}
                />
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="tags">Tag:</StrongLabel>
                <div style={{ width: '20em' }}>
                    <Tag onClick={() => {}}>tag</Tag>
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="toggle-box">ToggleBox:</StrongLabel>
                <ToggleBox className="active">BOX</ToggleBox>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="weekday-pattern">Weekday pattern &apos;default&apos;:</StrongLabel>
                <div style={{ width: '20em' }}>
                    <WeekdayPattern weekdays="0,1,4,6" large />
                </div>
                <WeekdayPattern weekdays="0,1,4,6" />
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="weekday-pattern">Weekday pattern &apos;strict&apos;:</StrongLabel>
                <div style={{ width: '20em' }}>
                    <WeekdayPattern weekdays="0,1,4,6" large type="strict" />
                </div>
                <WeekdayPattern weekdays="0,1,4,6" type="strict" />
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="color-picker">Color Picker:</StrongLabel>
                <ColorPicker
                    color={color}
                    id="color-picker-component"
                    onChange={(c: string) => {
                        setColor(c)
                    }}
                />
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="inputs">Inputs:</StrongLabel>
                <SubBlock>
                    <RegularLabel>Checkboxes:</RegularLabel>
                    <Checkbox />
                    <span style={{ fontSize: '.8em' }}>indeterminate state: </span>
                    <Checkbox indeterminateState={true} />
                    <BigCheckbox />
                </SubBlock>
                <SubBlock>
                    <RegularLabel>Radio:</RegularLabel>
                    <Radio
                        checked={radioChecked === 'a'}
                        value="a"
                        name="letters"
                        onChange={(e) => setRadioChecked(e.currentTarget.value as 'a' | 'b')}
                    />
                </SubBlock>
                <SubBlock>
                    <RegularLabel>Text inputs:</RegularLabel>
                    <TextInput style={{ margin: '.5em' }} placeholder="Default text input" />
                    <TextInput style={{ margin: '.5em' }} placeholder="Bordered text input" border />
                    <NumberInput
                        style={{ margin: '.5em', width: '12.5em' }}
                        placeholder="Number input (max 99)"
                        max={99}
                        min={10}
                        maxLength={7}
                    />
                    <NumberInput
                        style={{ margin: '.5em', width: '12.5em' }}
                        placeholder="...with currency"
                        currency={props.getCurrency('sealife')}
                    />
                    <NumberInput
                        style={{ margin: '.5em', width: '12.5em' }}
                        placeholder="...only integer"
                        integerOnly={true}
                    />
                    <TextInput style={{ margin: '.5em' }} disabled value="Disabled input" />
                    <div style={{ width: '16em', margin: '.5em' }}>
                        <WrapWithSymbol symbol="px" position="right">
                            <NumberInput value="Input with symbol right" align="right" />
                        </WrapWithSymbol>
                    </div>
                    <div style={{ width: '16em', margin: '.5em' }}>
                        <WrapWithSymbol position="left" symbol="$">
                            <NumberInput value="Input with symbol left" align="left" />
                        </WrapWithSymbol>
                    </div>
                    <div style={{ width: '16em', margin: '.5em' }}>
                        <TimeInput id="someTime" name="someTime" value="" onChange={() => {}} />
                    </div>
                    <TextInput style={{ margin: '.5em' }} disabled value="Disabled input" />
                    <TextInput style={{ margin: '.5em' }} placeholder="Status success" status="success" />
                    <TextInput style={{ margin: '.5em' }} placeholder="Status error" status="error" />
                    <div>
                        <IconTextInput
                            style={{ margin: '.5em' }}
                            placeholder="Icon text input"
                            iconLeft={faSearch}
                            iconRight={faCheck}
                        />
                    </div>
                </SubBlock>
                <SubBlock>
                    <RegularLabel>Text area:</RegularLabel>
                    <TextArea placeholder="this is a textarea" style={{ width: '50%', margin: '.5em' }} />
                </SubBlock>
                <SubBlock id="inlineEdit">
                    <RegularLabel>Inline Edits:</RegularLabel>
                    <p style={{ opacity: 0.5 }}>Date inline edit:</p>
                    <DatePicker date={new Date()} inlineEdit onInlineEditAccept={() => {}} />
                    <p style={{ opacity: 0.5 }}>Time inline edit:</p>
                    <InlineEdit id="time" value="12:20" onEditAccept={() => {}} timeField />
                    <p style={{ opacity: 0.5 }}>Text inline edit:</p>
                    <InlineEdit id="input" value="Click to change value" onEditAccept={() => {}} />
                    <p style={{ opacity: 0.5 }}>Disabled text inline edit:</p>
                    <InlineEdit id="input" value="Input value" disabled={true} onEditAccept={() => ''} />
                </SubBlock>
                <SubBlock>
                    <RegularLabel>Toggles: </RegularLabel>
                    <div style={{ margin: '.5em' }}>
                        <Toggle
                            isOn={toggleOn}
                            onClick={() => {
                                setToggleValue(!toggleOn)
                            }}
                        />
                    </div>
                    <div style={{ margin: '.5em' }}>
                        <PersistentToggle
                            right={toggleRight}
                            onClick={() => {
                                setToggleSideValue(!toggleRight)
                            }}
                        />
                    </div>
                    <div>
                        <ToggleWithValues
                            options={[
                                { name: '$', value: 'currency' },
                                { name: '%', value: 'percent' },
                            ]}
                            selected={activeToggleValue}
                            onClick={(v: 'currency' | 'percent') => {
                                setActiveToggleValue(v)
                            }}
                        />
                    </div>
                </SubBlock>
                <SubBlock style={{ maxWidth: '30em' }}>
                    <RegularLabel>Singleselect dropdown: </RegularLabel>
                    <SingleSelect
                        options={selectOptions}
                        noSelectOption="Click me to see options"
                        height="2.8em"
                        selected={null}
                        onSelect={() => {}}
                    />
                </SubBlock>
                <SubBlock style={{ maxWidth: '30em' }}>
                    <RegularLabel>Multiselect dropdown: </RegularLabel>
                    <MultiSelect
                        options={selectOptions}
                        noSelectOption="Click me to see options"
                        height="2.8em"
                        selected={[]}
                        onSelect={() => {}}
                    />
                </SubBlock>
                <SubBlock style={{ maxWidth: '30em' }}>
                    <RegularLabel>Slider horizontal: </RegularLabel>
                    <Slider
                        value={sliderValue}
                        onChange={setSliderValue}
                        steps={[
                            { value: 0, name: 'low' },
                            { value: 1, name: 'medium low' },
                            { value: 2, name: 'medium hight' },
                            { value: 3, name: 'high' },
                            { value: 4, name: 'super high' },
                        ]}
                        showCurrentValue
                        showLabels
                        labels={['Low', 'High']}
                        status="success"
                        striped
                    />
                    <SliderWithInput value={sliderValue} onChange={setSliderValue} min={0} max={4} status="success" />
                </SubBlock>
                <SubBlock style={{ maxWidth: '30em' }}>
                    <RegularLabel>Slider vertical: </RegularLabel>
                    <Row>
                        <Slider
                            value={verticalSliderValue}
                            onChange={setVerticalSliderValue}
                            step={3}
                            min={0}
                            max={100}
                            vertical
                            showCurrentValue
                            metric="Tue"
                            striped
                        />
                        <div style={{ marginLeft: '2em' }}>
                            <Slider
                                value={verticalSliderValue}
                                onChange={setVerticalSliderValue}
                                step={3}
                                min={0}
                                max={100}
                                vertical
                                showCurrentValue
                                metric="Tue"
                                stretch={7}
                            />
                        </div>
                    </Row>
                </SubBlock>
                <SubBlock style={{ maxWidth: '30em' }}>
                    <RegularLabel>Checkbox column: </RegularLabel>
                    <CheckboxColumn values={checkboxColumnValues} onChange={setCheckboxColumnValues} metric="Tue" />
                </SubBlock>
            </ComponentBlock>
            <ComponentBlock style={{ position: 'relative' }}>
                <StrongLabel id="popover">Popover (will appear in 2s):</StrongLabel>
                <div style={{ position: 'absolute', left: '15em', top: '-1em' }}>
                    <Popover arrow="left">
                        <div>This is a popover text. Please add any element to the popover: buttons, links, etc.</div>
                        <ActionButton style={{ margin: '.5em' }}>AltPrimary button</ActionButton>
                    </Popover>
                </div>
                <div style={{ position: 'absolute', left: '45em', top: '-1em' }}>
                    <Popover arrow="bottom" width="15em">
                        <div>This is a popover text. Please add any element to the popover.</div>
                    </Popover>
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="tabs">Tabs:</StrongLabel>
                <Tabs titles={['Paris', 'Rome', 'Berlin']}>
                    <div>wonderful city</div>
                    <div>historic city</div>
                    <div>spectacular city</div>
                </Tabs>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="tooltip">Tooltip (currently used in charts):</StrongLabel>
                <div style={{ margin: '.5em', width: '10em' }}>
                    <Tooltip direction="left">Tooltip left</Tooltip>
                </div>
                <div style={{ margin: '.5em', width: '15em' }}>
                    <Tooltip style={{ color: '#FFF' }} direction="right">
                        Tooltip right, style can be customized
                    </Tooltip>
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="infotip">Infotip:</StrongLabel>
                <div style={{ display: 'flex' }}>
                    <div style={{ margin: '.5em', display: 'flex', position: 'relative' }}>
                        This is needs more explanation
                        <Infotip pointer="left">Infotip pointer left</Infotip>
                    </div>
                    <div style={{ margin: '.5em', display: 'flex', position: 'relative' }}>
                        This also<Infotip pointer="right">Infotip pointer right</Infotip>
                    </div>
                </div>
                <div style={{ marginTop: '1em' }}>
                    <DisabledItemHoverInfotip
                        active
                        infotipText="This button is disabled because of some very important reason"
                        width="30em"
                    >
                        <ActionButton disabled kind="action" secondary onClick={() => {}}>
                            Hover me to see why I&apos;m disabled
                        </ActionButton>
                    </DisabledItemHoverInfotip>
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <NavigationBack onClick={() => {}} text="Navigate back to something" topOffset="-4em" />
            </ComponentBlock>
            <ComponentBlock>
                <NavigationTabs
                    tabs={[
                        { name: 'Some link', onClick: () => {}, current: false },
                        { name: 'Another link', onClick: () => {}, current: false },
                        {
                            name: 'The current/active link',
                            onClick: () => {},
                            current: true,
                        },
                    ]}
                />
            </ComponentBlock>
            <ComponentBlock style={{ marginTop: '10em' }}>
                <StrongLabel id="breadcrumbs">Breadcrumbs:</StrongLabel>
                <div style={{ position: 'relative', width: '50em' }}>
                    <BreadCrumbs
                        path={[
                            { name: 'First step', path: '#' },
                            { name: 'Second step', path: '#' },
                            { name: 'Third step', path: '' },
                        ]}
                    />
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="pagination">Pagination:</StrongLabel>
                <div style={{ background: '#F5F7FA', padding: '0.3em 1em', height: '5em' }}>
                    <PaginationSection
                        pagination={{ page: 1, pageSize: 20 }}
                        onPaginationChanged={() => {}}
                        totalItemsCount={1000}
                    />
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="card">Card:</StrongLabel>
                <Card style={{ width: '30em', height: '5em', margin: '.5em' }}>
                    This is used on profile page for different sections.
                </Card>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="infoCard">Alert (Material UI):</StrongLabel>
                <Row bottomSpacing={12}>
                    <Col span={6}>
                        <Alert severity="info">
                            <AlertTitle>Alert: info</AlertTitle> Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Alert>
                    </Col>
                    <Col span={6}>
                        <Alert severity="error" action={<Button color="inherit">Take action</Button>}>
                            <AlertTitle>Alert: error</AlertTitle> Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Alert>
                    </Col>
                </Row>
                <Row bottomSpacing={12}>
                    <Col span={6}>
                        <Alert severity="success" onClose={() => {}}>
                            <AlertTitle>Alert: success</AlertTitle> Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Alert>
                    </Col>
                    <Col span={6}>
                        <Alert severity="warning">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </Alert>
                    </Col>
                </Row>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="infoCard">Info card (deprecated):</StrongLabel>
                <Row>
                    <Col span={6}>
                        <InfoCard
                            active
                            type="info"
                            cardHeader="Info card: info"
                            cardText={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.`}
                        />
                    </Col>
                    <Col span={6}>
                        <InfoCard
                            active
                            type="example"
                            cardHeader="Info card: example"
                            cardText={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.`}
                        />
                    </Col>
                </Row>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="messages">Status message container (deprecated):</StrongLabel>
                <Row>
                    <Col span={6}>
                        <StatusMessageContainer
                            title="warn message container"
                            iconSrc={lockIcon}
                            onDismiss={() => {}}
                            status="warn"
                        >
                            <div>Text or other type of content</div>
                        </StatusMessageContainer>
                    </Col>
                    <Col span={6}>
                        <StatusMessageContainer title="success message container" iconSrc={lockIcon} status="success">
                            <div>Text or other type of content</div>
                        </StatusMessageContainer>
                    </Col>
                </Row>
                <Row style={{ marginTop: '1em' }}>
                    <Col span={6}>
                        <StatusMessageContainer title="error message container" iconSrc={lockIcon} status="error">
                            <div>Text or other type of content</div>
                        </StatusMessageContainer>
                    </Col>
                    <Col span={6} />
                </Row>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="fonts">Fonts:</StrongLabel>
                <span style={{ margin: '.5em', fontFamily: 'Roboto, sans-serif' }}>
                    Primary font - Roboto, sans-serif
                </span>
                <span style={{ margin: '.5em', fontFamily: 'Roboto Condensed, sans-serif' }}>
                    Secondary font - Roboto Condensed, sans-serif
                </span>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="separators">Separators:</StrongLabel>
                <HorizontalLine />
                <TextWithHorizontalLine text="text in the middle" alignment="center" />
                <TextWithHorizontalLine text="text on the left" alignment="left" />
                <TextWithHorizontalLine text="text on the right" alignment="right" />
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="inline-table">Alterating Data Table:</StrongLabel>
                <div style={{ padding: '1em' }}>
                    <InlineDataTable columnWidths={columnWidths}>
                        <HeaderRow>
                            <TableHeader>Id</TableHeader>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Address</TableHeader>
                            <TableHeader nonInteractive />
                        </HeaderRow>
                        <DataRow narrow>
                            <Cell>1</Cell>
                            <Cell>Auggi</Cell>
                            <Cell>Nice street 1</Cell>
                            <Cell>Do smth</Cell>
                        </DataRow>
                        <DataRow narrow>
                            <Cell>2</Cell>
                            <Cell>Piri</Cell>
                            <Cell>Nice street 2</Cell>
                            <Cell>Do smth</Cell>
                        </DataRow>
                        <DataRow narrow>
                            <Cell>3</Cell>
                            <Cell>Teresa May</Cell>
                            <Cell>Nice street 3</Cell>
                            <Cell>Do smth</Cell>
                        </DataRow>
                    </InlineDataTable>
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="table">Bordered Data Table:</StrongLabel>
                <div style={{ padding: '1em' }}>
                    <InlineDataTable columnWidths={columnWidths} id="products-list-page" bordered>
                        <HeaderRow>
                            <TableHeader>Id</TableHeader>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Address</TableHeader>
                            <TableHeader nonInteractive />
                        </HeaderRow>
                        <DataRow narrow>
                            <Cell>1</Cell>
                            <Cell>Auggi</Cell>
                            <Cell>Nice street 1</Cell>
                            <Cell>Do smth</Cell>
                        </DataRow>
                        <DataRow narrow>
                            <Cell>2</Cell>
                            <Cell>Piri</Cell>
                            <Cell>Nice street 2</Cell>
                            <Cell>Do smth</Cell>
                        </DataRow>
                        <DataRow narrow>
                            <Cell>3</Cell>
                            <Cell>Teresa May</Cell>
                            <Cell>Nice street 3</Cell>
                            <Cell>Do smth</Cell>
                        </DataRow>
                    </InlineDataTable>
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="scrollable-table">Scrollable Data Table:</StrongLabel>
                <div style={{ padding: '1em' }}>
                    <ScrollTableContainer loading={false} accountSlug="accountSlug">
                        <DataTable columnWidths={scrollableTableColumnWidths} allowOverflow>
                            <HeaderRow>
                                <TableHeader>Id</TableHeader>
                                <TableHeader>Name</TableHeader>
                                <TableHeader>Address</TableHeader>
                                <TableHeader>Name</TableHeader>
                                <TableHeader>Address</TableHeader>
                                <TableHeader>Name</TableHeader>
                                <TableHeader>Address</TableHeader>
                                <TableHeader nonInteractive />
                            </HeaderRow>
                            <DataRow>
                                <Cell>1</Cell>
                                <Cell>Auggi</Cell>
                                <Cell>Nice street 1</Cell>
                                <Cell>Auggi</Cell>
                                <Cell>Nice street 1</Cell>
                                <Cell>Auggi</Cell>
                                <Cell>Nice street 1</Cell>
                                <Cell>Do smth</Cell>
                            </DataRow>
                            <DataRow>
                                <Cell>2</Cell>
                                <Cell>Piri</Cell>
                                <Cell>Nice street 2</Cell>
                                <Cell>Piri</Cell>
                                <Cell>Nice street 2</Cell>
                                <Cell>Piri</Cell>
                                <Cell>Nice street 2</Cell>
                                <Cell>Do smth</Cell>
                            </DataRow>
                        </DataTable>
                    </ScrollTableContainer>
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="no-results-row">Table no results row:</StrongLabel>
                <div style={{ width: '60em', margin: '.5em' }}>
                    <NoResultsRow item="something" />
                </div>
            </ComponentBlock>
            <ComponentBlock>
                <StrongLabel id="charts">Charts:</StrongLabel>
                <div style={{ padding: '1em' }}>
                    <PieChart title="Pie chart" data={exampleChartData} loading={false} />
                </div>
                <div style={{ padding: '1em' }}>
                    <BarChart title="Two series Bar chart" series={exampleBarChartSeries} loading={false} />
                </div>
                <div style={{ padding: '1em' }}>
                    <BarChart
                        title="Single series Bar chart"
                        series={[exampleBarChartSeries[0]]}
                        rotateNames
                        loading={false}
                    />
                </div>
                <div style={{ padding: '1em' }}>
                    <ChartWrapperWithTotals total="100" title="Time Series chart">
                        <TimeseriesBarChart series={exampleChartSeries} axisData={exampleAxisData} loading={false} />
                    </ChartWrapperWithTotals>
                </div>
            </ComponentBlock>
        </Container>
    )
}

export default withCurrency(withTheme(DashboardComponents))
